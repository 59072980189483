import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'

import WIVPage from '../components/WIVPage'

const WivReport = ({ data }) => {
  const {
    allReports: { edges: allReports },
  } = data

  return (
    <Layout>
      <WIVPage
        pageData={data.contentfulWomenInVenture}
        reports={allReports}
        hideCta
      />
    </Layout>
  )
}

export default WivReport

export const pageQuery = graphql`
  query($slug: Int!) {
    allReports: allContentfulWomenInVenture(
      sort: { fields: year, order: DESC }
    ) {
      edges {
        node {
          year
        }
      }
    }
    contentfulWomenInVenture(year: { eq: $slug }) {
      pageHeader {
        heading
        subheading
        image {
          title
          file {
            url
          }
        }
        metaTitle
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
        metaSiteUrl
        metaTwitter
      }
      ctaText
      abstractRtf {
        childContentfulRichText {
          html
        }
      }
      bodyRtf {
        childContentfulRichText {
          html
        }
      }
      documents {
        id
        title
        link
        linkNewsletter
      }
    }
  }
`
